import './App.css';

function App() {
	return (
		<div className="App" id='app'>
			<header>
				<div className="container ">
					<div className="header d-lg-flex mb-2">
						<div className="header-agile d-flex ">
							<h1 className="mr-lg-0 mr-4">
								<a className="navbar-brand logo" href="index.html">
									<span className="fa fa-stumbleupon" aria-hidden="true"></span>
								</a>
							</h1>
							<div className="social social-invisible mt-3">
								<ul>
									<li className="mr-sm-3 mr-2 icons"><a href="https://www.instagram.com/unfade.clothing/"><span className="fa fa-instagram" aria-hidden="true"></span></a>
									</li>
									<li className="mr-sm-3 mr-2 icons"><a href="#"><span className="fa fa-facebook" aria-hidden="true"></span></a>
									</li>
									{/* <li className="mr-sm-3 mr-2 icons"><a href="#"><span className="fa fa-twitter" aria-hidden="true"></span></a></li> */}
									{/* <li className="icons"><a href="#"><span className="fa fa-pinterest" aria-hidden="true"></span></a></li> */}
								</ul>
							</div>
						</div>
						<div className="nav_w3ls mt-3">
							<nav id='nav'>
								<label htmlFor="drop" className="toggle mt-lg-0 mt-1" ><span className="fa fa-bars" aria-hidden="true"></span></label>
								<input type="checkbox" id="drop" className='checking' />
								<ul className="menu" id='menu'>
									<li className="mr-lg-3 mr-2 active"><a href="#home">Home</a></li>
									<li className="mr-lg-3 mr-2"><a href="#about">About </a></li>
									<li className="mr-lg-3 mr-2"><a href="#services">Services</a></li>
									<li className="mr-lg-3 mr-2"><a href="#subscribe">Commuinity</a></li>
									{/* <li className="mr-lg-3 mr-2 p-0">

										<label htmlFor="drop-2" className="toggle">Dropdown <span className="fa fa-angle-down" aria-hidden="true"></span>
										</label>
										<a href="#">Dropdown <span className="fa fa-angle-down" aria-hidden="true"></span></a>
										<input type="checkbox" id="drop-2" />
										<ul className="inner-dropdown">
											<li><a href="#services">Services</a></li>
											<li><a href="#subscribe">Commuinity</a></li>
										</ul>
									</li> */}
									{/* <li className="mr-lg-3 mr-2"><a href="#gallery">Gallery</a></li> */}
									<li className="mr-lg-3 mr-2"><a href="#contact">Contact Us</a></li>
								</ul>
							</nav>
						</div>
						<div className="social mt-3 ml-auto">
							<ul>
								<li className="mr-3 icons"><a href="https://www.instagram.com/unfade.clothing/" target="_blank" ><span className="fa fa-instagram" aria-hidden="true"></span></a></li>
								<li className="mr-3 icons"><a href="#"><span className="fa fa-facebook" aria-hidden="true"></span></a></li>
								{/* <li className="mr-3 icons"><a href="#"><span className="fa fa-twitter" aria-hidden="true"></span></a></li> */}
								{/* <li className=" icons"><a href="#"><span className="fa fa-pinterest" aria-hidden="true"></span></a></li> */}
							</ul>
						</div>
					</div>
				</div>
			</header>

			<section className="main-slider" id='home'>
				<div className="owl-one owl-carousel owl-theme">
					<div className="item">
						<li>
							<div className="banner-view">
								<div className="container banner-info">
									<h5><span className="fa fa-stumbleupon" aria-hidden="true"></span> UNFADE APPARELS</h5>
									<h2 className="movetxt agile-title text-capitalize">You Either Know Fashion </h2>
									<h2 className="movetxt mb-3 agile-title text-capitalize">Or You Dont</h2>
									<a href="#" className="btn"> LAUNCHING SOON... </a>
								</div>
							</div>
						</li>
					</div>
					<div className="item">
						<li>
							<div className="banner-view banner-top1">
								<div className="container banner-info">
									<h5><span className="fa fa-stumbleupon" aria-hidden="true"></span> Trending Fashion</h5>
									<h4 className="movetxt agile-title text-capitalize">Fashion Is A Reflection </h4>
									<h4 className="movetxt mb-3 agile-title text-capitalize">of Your Style</h4>
									<a href="#" className="btn"> LAUNCHING SOON... </a>
								</div>
							</div>
						</li>
					</div>
					<div className="item">
						<li>
							<div className="banner-view banner-top2">
								<div className="container banner-info">
									<h5><span className="fa fa-stumbleupon" aria-hidden="true"></span> Style Makeup</h5>
									<h4 className="movetxt agile-title text-capitalize">An Influential Platform for</h4>
									<h4 className="movetxt mb-3 agile-title text-capitalize">Fashion Industry</h4>
									<a href="#" className="btn"> LAUNCHING SOON... </a>
								</div>
							</div>
						</li>
					</div>
				</div>
			</section >

			<section className="about py-5" id="about">
				<div className="container py-lg-5 py-md-3">
					<h3 className='heading mb-5 text-center'>About Us</h3>
					<div className="row">
						<div className="col-lg-6 about-padding">
							<h4 className="mt-lg-5">Fashion Creates Your Beauty</h4>
							<p className="mt-3">We take pride in offering you a topwear fashion store that combines style, comfort, and affordability like never before. Our commitment to being budget-friendly doesn't mean we compromise on the quality of our products; in fact, it's quite the opposite..</p>
						</div>
						<div className="col-lg-6 px-sm-0 img-div">
							<img src="images/ab3.jpg" alt="" className="img-fluid" />
							{/* <img src="images/ab1-alt.jpg" alt="" className="img-fluid position-img" /> */}
						</div>
						<div className="col-lg-6 px-sm-0 img-div mt-lg-0 mt-md-4 mt-0">
							<img src="images/ab4.jpg" alt="" className="img-fluid" />
							{/* <img src="images/ab2-alt.jpg" alt="" className="img-fluid position-img1" /> */}
						</div>
						<div className="col-lg-6 about-padding">
							<h4 className="mt-lg-5 mt-5">Trends With UNFADE</h4>
							<p className="mt-3">With our trending wears, you'll have the power to express yourself in a way that's both contemporary and captivating. Our commitment to quality ensures that every piece not only aligns with the latest trends but also meets the high standards you deserve.</p>
						</div>
					</div>
				</div>
			</section>

			<section className="services py-5" id="services">
				<div className="container py-xl-5 py-lg-3">
					<div className="row py-xl-3">
						<div className="col-lg-4 mt-lg-5">
							<p className="title">Our Services</p>
							<h3 className="heading mb-4">We Are Best In</h3>
							<p>Our team of dedicated fashion experts is here to assist you every step of the way. Whether you're seeking style advice, have questions about products, or need help with sizing, our personalized assistance ensures that you make informed choices.</p>
						</div>
						<div className="col-lg-8 mt-lg-0 mt-5">
							<div className="row">
								<div className="col-md-6">
									<div className="bottom-gd-ser p-4">
										<div className="row">
											<div className="col-sm-2 bottom-gd-icon">
												<span className="fa fa-shopping-bag" aria-hidden="true"></span>
											</div>
											<div className="col-sm-10 bottom-gd-content mt-sm-0 mt-4">
												<h4 className="mb-sm-3 mb-2">Trending Selection</h4>
												<p>We have best aesthetically trending clothes..</p>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-6 my-md-0 my-4">
									<div className="bottom-gd-ser p-4">
										<div className="row">
											<div className="col-sm-2 bottom-gd-icon">
												<span className="fa fa-check" aria-hidden="true"></span>
											</div>
											<div className="col-sm-10 bottom-gd-content mt-sm-0 mt-4">
												<h4 className="mb-sm-3 mb-2">Quality Assurance</h4>
												<p>We're confident about quality of our topwear..</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row mt-md-5">
								<div className="col-md-6">
									<div className="bottom-gd-ser p-4">
										<div className="row">
											<div className="col-sm-2 bottom-gd-icon">
												<span className="fa fa-credit-card" aria-hidden="true"></span>
											</div>
											<div className="col-sm-10 bottom-gd-content mt-sm-0 mt-4">
												<h4 className="mb-sm-3 mb-2">Affordability </h4>
												<p>When you choose us, It's More for Your Money</p>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-6 mt-md-0 mt-4">
									<div className="bottom-gd-ser p-4">
										<div className="row">
											<div className="col-sm-2 bottom-gd-icon">
												<span className="fa fa-truck" aria-hidden="true"></span>
											</div>
											<div className="col-sm-10 bottom-gd-content mt-sm-0 mt-4">
												<h4 className="mb-sm-3 mb-2">Fast Shipping</h4>
												<p>Quick response, Your satisfaction is our priority.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="contact py-5" id="contact">
				<div className="container py-md-5">
					<div className="row">
						<div className="col-lg-6 contact-left my-auto">
							<p>Contact Us	</p>
							<h3 className="heading">Get In Touch</h3>
							<p className="mt-3">If you have any queries, or want to speak with us, Please contact us for more details by below
								given information.</p>

						</div>

						<div className="col-lg-1 mb-4"></div>

						<div className="insta col-lg-5 my-auto">
								<a href="https://www.instagram.com/unfade.clothing/" className='align-items-center' target="_blank">
									<span><i class="fa fa-instagram"></i></span>
									<h4 >@unfade.clothing</h4>
									<button>Follow</button>
								</a>
						</div>
						
						{/* <div className="col-lg-8 about-text">
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4016656.5432275413!2d73.4953710906968!3d10.538722633438178!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b0812ffd49cf55b%3A0x64bd90fbed387c99!2sKerala!5e0!3m2!1sen!2sin!4v1691308659413!5m2!1sen!2sin"
								className="map" allowFullScreen=""></iframe>
						</div> */}
						{/* <div className="col-lg-8 mt-5">
							<form netlify>
								<div className="row main-w3layouts-sectns">
									<div className="col-md-6 w3-btm-spc form-text1">
										<input type="text" name="Name" placeholder="Enter Your Name" required="" />
									</div>
									<div className="col-md-6 w3-btm-spc form-text2">
										<input type="text" name="Phone no" placeholder="Enter Phone Number" required="" />
									</div>
								</div>
								<div className="row main-w3layouts-sectns">
									<div className="col-md-6 w3-btm-spc form-text1">
										<input type="email" name="email" placeholder="Enter Your Email" required="" />
									</div>
									<div className="col-md-6 w3-btm-spc form-text2">
										<input type="text" name="subject" placeholder="Subject" required="" />
									</div>
								</div>
								<div className="main-w3layouts-sectns ">
									<div className="w3-btm-spc form-text2 p-0">
										<textarea placeholder="Enter Your Message Here"></textarea>
									</div>
								</div>
								<button className="btn" type='submit'>Submit</button>
							</form>
						</div> */}
						{/* <div className="col-lg-4 mt-5">
							<div className="contact-info">
								<div className="footer-style-w3ls">
									<p><span className="fa fa-map-marker" aria-hidden="true"></span><strong> Location</strong> : All over Kerala</p>
								</div>
								<div className="footer-style-w3ls mt-2">
									<p><span className="fa fa-phone" aria-hidden="true"></span><strong> Phone</strong> : +121 098 8907 9987</p>
								</div>

								<div className="footer-style-w3ls mt-2">
									<p><span className="fa fa-envelope" aria-hidden="true"></span><strong> Email</strong> : <a
										href="mailto:info@example.com">info@example.com</a></p>
								</div>
							</div>
						</div> */}
					</div>
				</div>
			</section>

			<section className="gallery py-5" id="gallery">
				<div className="container py-lg-5 py-3">
					{/* <p class="text-center">Our Fashion Models</p> */}
					{/* <h3 className="heading mb-4 text-center">Fashion Gallery</h3> */}
					<div className="row news-grids text-center">
						<div className="col-md-4 col-sm-6 gal-img">
							<a><img src="images/pexelsphoto1225035.jpeg" alt="news image" className="img-fluid" /></a>
							<a><img src="images/pexelsphoto371095.jpeg" alt="news image" className="img-fluid mt-2" /></a>

						</div>

						<div className="col-md-4 col-sm-6 gal-img">
							<a><img src="images/pexelsphoto432059.jpeg" alt="news image" className="img-fluid" /></a>
							<a><img src="images/pexelsphoto1060298.jpeg" alt="news image" className="img-fluid " /></a>
						</div>

						<div className="col-md-4 col-sm-6 gal-img">
							<a><img src="images/pexelsphoto270859.jpeg" alt="news image" className="img-fluid" /></a>
							<a><img src="images/pexelsphoto447570.jpeg" alt="news image" className="img-fluid" /></a>
							<a><img src="images/pexelsphoto375880.jpeg" alt="news image" className="img-fluid" /></a>
						</div>
					</div>
				</div>

				{/* <div id="gal1" className="pop-overlay animate">
					<div className="popup">
						<img src="images/1.jpg" alt="Popup Image" className="img-fluid" />
						<p className="mt-4">Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
						<a className="close" href="#gallery">&times;</a>
					</div>
				</div>

				<div id="gal2" className="pop-overlay animate">
					<div className="popup">
						<img src="images/2.jpg" alt="Popup Image" className="img-fluid" />
						<p className="mt-4">Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
						<a className="close" href="#gallery">&times;</a>
					</div>
				</div>

				<div id="gal3" className="pop-overlay animate">
					<div className="popup">
						<img src="images/3.jpg" alt="Popup Image" className="img-fluid" />
						<p className="mt-4">Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
						<a className="close" href="#gallery">&times;</a>
					</div>
				</div>

				<div id="gal4" className="pop-overlay animate">
					<div className="popup">
						<img src="images/4.jpg" alt="Popup Image" className="img-fluid" />
						<p className="mt-4">Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
						<a className="close" href="#gallery">&times;</a>
					</div>
				</div>

				<div id="gal5" className="pop-overlay animate">
					<div className="popup">
						<img src="images/5.jpg" alt="Popup Image" className="img-fluid" />
						<p className="mt-4">Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
						<a className="close" href="#gallery">&times;</a>
					</div>
				</div>

				<div id="gal6" className="pop-overlay animate">
					<div className="popup">
						<img src="images/6.jpg" alt="Popup Image" className="img-fluid" />
						<p className="mt-4">Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
						<a className="close" href="#gallery">&times;</a>
					</div>
				</div> */}

			</section>

			<section className="stats-info" id="facts">
				<div className="overlay py-5">
					<div className="container py-lg-5">
						<p className="center text-center">UNFADE APPARELS</p>
						<h3 className="heading mb-5 text-center">lAUNCHING SOON....</h3>
						{/* <div className="row">
							<div className="col-lg-3 col-sm-6 stats-grid-w3-agile">
								<div className="row">
									<div className="col-4">
										<div className="icon-right-w3ls text-center">
											<span className="fa fa-female"></span>
										</div>
									</div>
									<div className="col-8">
										<p className="counter text-wh">1500+</p>
										<p className="text-li">Fashion Models</p>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-sm-6 stats-grid-w3-agile mt-sm-0 mt-4">
								<div className="row">
									<div className="col-4">
										<div className="icon-right-w3ls text-center">
											<span className="fa fa-trophy"></span>
										</div>
									</div>
									<div className="col-8">
										<p className="counter text-wh">800</p>
										<p className="text-li">Awards Won</p>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-sm-6 stats-grid-w3-agile mt-lg-0 mt-4">
								<div className="row">
									<div className="col-4">
										<div className="icon-right-w3ls text-center">
											<span className="fa fa-weibo"></span>
										</div>
									</div>
									<div className="col-8">
										<p className="counter text-wh">600</p>
										<p className="text-li">Fashion Shows</p>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-sm-6 stats-grid-w3-agile mt-lg-0 mt-4">
								<div className="row">
									<div className="col-4">
										<div className="icon-right-w3ls text-center">
											<span className="fa fa-smile-o"></span>
										</div>
									</div>
									<div className="col-8">
										<p className="counter text-wh">1000+</p>
										<p className="text-li">Positive Feedback</p>
									</div>
								</div>
							</div>
						</div> */}
					</div>
				</div>
			</section>

			<section className="subscribe-text py-5" id="subscribe">
				<div className="container py-lg-3">
					<div className="row">
						<div className="col-lg-6 my-auto">
							<h3 className="heading mb-3">Join to Our Community</h3>
							<p>By Joining to our Community you will always get latest Fashion news and updates from us. Join Now !!</p>
						</div>
						<div className="col-lg-2"></div>
						<div className="col-lg-4 text-center mt-3">
							<img className='qrcode' src="images/QR.jpeg" alt="" />
							{/* <form action="#" method="post">
								<input type="email" name="Email" placeholder="Enter your email..." required="" />
								<button className="btn1"><span className="fa fa-paper-plane" aria-hidden="true"></span></button>
								<div className="clearfix"> </div>
							</form> */}
							{/* <p className="mt-3">We respect your privacy and will never share your email address with any person or
								organization.</p> */}
						</div>
					</div>
				</div>
			</section>

			<footer className="footerv4-w3ls py-sm-5 py-4" id="footer">
				<div className="footerv4-top">
					<div className="container">
						<div className="footer-logo text-center">
							<a href="index.html"><span className="fa fa-stumbleupon" aria-hidden="true"></span> UNFADE APPARELS </a>
						</div>

						<div className="d-flex align-items-center footer-nav-wthree justify-content-center my-3">
							<ul className="footer-link">
								<li>
									<a href="#home">Home</a>
								</li>
								<li>
									<a href="#about">About Us</a>
								</li>
								<li>
									<a href="#services">Services</a>
								</li>
								<li>
									<a href="#contact">Contact</a>
								</li>
	
							</ul>
						</div>

						<div className="text-center">
							<a href="#home" className="move-top text-center"><span className="fa fa-angle-double-up"
								aria-hidden="true"></span></a>
						</div>

						<div className="copy-right text-center">
							<p>Copy Right © UNFADE 2023
							</p>
						</div>


					</div>
				</div>
			</footer>

		</div >
	);
}

export default App;
